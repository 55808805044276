export const Shiyu7_1 = [
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 26.55,
    rank: 1,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 26.55,
    rank: 1,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 7.61,
    rank: 3,
    avg_round: 2.82,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 6.19,
    rank: 4,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 4.77,
    rank: 5,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 4.37,
    rank: 6,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 3.24,
    rank: 7,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 2.62,
    rank: 8,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 1.17,
    rank: 9,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.98,
    rank: 10,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.98,
    rank: 10,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.91,
    rank: 12,
    avg_round: 2.69,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.91,
    rank: 12,
    avg_round: 2.74,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.8,
    rank: 14,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.69,
    rank: 15,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.51,
    rank: 16,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.47,
    rank: 17,
    avg_round: 2.82,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.4,
    rank: 18,
    avg_round: 2.55,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.36,
    rank: 19,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.33,
    rank: 20,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.33,
    rank: 20,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.25,
    rank: 22,
    avg_round: 2.57,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.22,
    rank: 23,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.22,
    rank: 23,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.22,
    rank: 23,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.18,
    rank: 26,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.18,
    rank: 26,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 26,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 26,
    avg_round: 2.4,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.15,
    rank: 30,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.15,
    rank: 30,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.15,
    rank: 30,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.15,
    rank: 30,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.15,
    rank: 30,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.15,
    rank: 30,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Soukaku',
    app_rate: 0.15,
    rank: 30,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 37,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 37,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 37,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 37,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 37,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 37,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 37,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 37,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.11,
    rank: 37,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Ben',
    char_three: 'Soukaku',
    app_rate: 0.11,
    rank: 37,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.11,
    rank: 37,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.11,
    rank: 37,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 37,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 50,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Lycaon',
    app_rate: 0.07,
    rank: 50,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.07,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.07,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 50,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.07,
    rank: 50,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.07,
    rank: 50,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.07,
    rank: 50,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.07,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 50,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 69,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 69,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Soukaku',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 69,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Lycaon',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 69,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Lycaon',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Ben',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Lycaon',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  }
];
