export const Shiyu7_2 = [
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 13.66,
    rank: 1,
    avg_round: 2.95,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 12.42,
    rank: 2,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 9.58,
    rank: 3,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 8.52,
    rank: 4,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 4.84,
    rank: 5,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 4.22,
    rank: 6,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 3.31,
    rank: 7,
    avg_round: 2.81,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 2.26,
    rank: 8,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 1.89,
    rank: 9,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 1.38,
    rank: 10,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 1.27,
    rank: 11,
    avg_round: 2.97,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 1.24,
    rank: 12,
    avg_round: 2.72,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 1.24,
    rank: 12,
    avg_round: 2.82,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 1.24,
    rank: 12,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 1.09,
    rank: 15,
    avg_round: 2.84,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 1.06,
    rank: 16,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 1.06,
    rank: 16,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 1.06,
    rank: 16,
    avg_round: 2.81,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.95,
    rank: 19,
    avg_round: 2.84,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.87,
    rank: 20,
    avg_round: 2.81,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.84,
    rank: 21,
    avg_round: 2.7,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.84,
    rank: 21,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.8,
    rank: 23,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.8,
    rank: 23,
    avg_round: 2.57,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.66,
    rank: 25,
    avg_round: 2.2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.66,
    rank: 25,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.62,
    rank: 27,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.58,
    rank: 28,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.58,
    rank: 28,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.55,
    rank: 30,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.51,
    rank: 31,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.47,
    rank: 32,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.47,
    rank: 32,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.44,
    rank: 34,
    avg_round: 2.42,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.4,
    rank: 35,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.4,
    rank: 35,
    avg_round: 2.82,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.4,
    rank: 35,
    avg_round: 2.82,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.36,
    rank: 38,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.36,
    rank: 38,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.36,
    rank: 38,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.36,
    rank: 38,
    avg_round: 2.78,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.33,
    rank: 42,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Seth',
    app_rate: 0.33,
    rank: 42,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.29,
    rank: 44,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.29,
    rank: 44,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.29,
    rank: 44,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.25,
    rank: 47,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.25,
    rank: 47,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.25,
    rank: 47,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.22,
    rank: 50,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.22,
    rank: 50,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.22,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.22,
    rank: 50,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.18,
    rank: 54,
    avg_round: 2.25,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.18,
    rank: 54,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.18,
    rank: 54,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.18,
    rank: 54,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.18,
    rank: 54,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.15,
    rank: 59,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.15,
    rank: 59,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.15,
    rank: 59,
    avg_round: 2.25,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 59,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.15,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.15,
    rank: 59,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.15,
    rank: 59,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.15,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.11,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.11,
    rank: 67,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.11,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.11,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.11,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.11,
    rank: 67,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.07,
    rank: 80,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.07,
    rank: 80,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.07,
    rank: 80,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 80,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 80,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 80,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 80,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 80,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 80,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 80,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 80,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 80,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 80,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.07,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 80,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 111,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'grace-howard',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 111,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Ben',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 111,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'Piper',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 111,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 111,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lycaon',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Anton',
    char_three: 'grace-howard',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 111,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 111,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Ben',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'jane-doe',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 111,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Corin',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 111,
    avg_round: 3,
    star_num: '4'
  }
];
