export const Shiyu6_2 = [
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 12.69,
    rank: 1,
    avg_round: 2.99,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 11.28,
    rank: 2,
    avg_round: 2.98,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 9.72,
    rank: 3,
    avg_round: 2.98,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 7.44,
    rank: 4,
    avg_round: 2.99,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 4.46,
    rank: 5,
    avg_round: 2.98,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 4.32,
    rank: 6,
    avg_round: 2.98,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 3.98,
    rank: 7,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 2.46,
    rank: 8,
    avg_round: 2.97,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 1.97,
    rank: 9,
    avg_round: 2.98,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 1.69,
    rank: 10,
    avg_round: 2.85,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 1.59,
    rank: 11,
    avg_round: 2.97,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 1.45,
    rank: 12,
    avg_round: 2.97,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 1.42,
    rank: 13,
    avg_round: 2.97,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 1.25,
    rank: 14,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 1.25,
    rank: 14,
    avg_round: 2.97,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 1.14,
    rank: 16,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 1.11,
    rank: 17,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 1.07,
    rank: 18,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 1.07,
    rank: 18,
    avg_round: 2.97,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 1.0,
    rank: 20,
    avg_round: 2.97,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.97,
    rank: 21,
    avg_round: 2.88,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.97,
    rank: 21,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.9,
    rank: 23,
    avg_round: 2.95,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.83,
    rank: 24,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.83,
    rank: 24,
    avg_round: 2.95,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.73,
    rank: 26,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.66,
    rank: 27,
    avg_round: 2.84,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.62,
    rank: 28,
    avg_round: 2.88,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.55,
    rank: 29,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.52,
    rank: 30,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.48,
    rank: 31,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.45,
    rank: 32,
    avg_round: 2.85,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.45,
    rank: 32,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.45,
    rank: 32,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.45,
    rank: 32,
    avg_round: 2.92,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.42,
    rank: 36,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.42,
    rank: 36,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.38,
    rank: 38,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.38,
    rank: 38,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.38,
    rank: 38,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.35,
    rank: 41,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.35,
    rank: 41,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.31,
    rank: 43,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.31,
    rank: 43,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.28,
    rank: 45,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.28,
    rank: 45,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Seth',
    app_rate: 0.28,
    rank: 45,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.28,
    rank: 45,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.24,
    rank: 49,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.24,
    rank: 49,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.24,
    rank: 49,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.21,
    rank: 52,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.21,
    rank: 52,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.21,
    rank: 52,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.21,
    rank: 52,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 56,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.17,
    rank: 56,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.17,
    rank: 56,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.14,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.14,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.14,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.14,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.14,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.14,
    rank: 59,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.14,
    rank: 59,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.1,
    rank: 67,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.1,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.1,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.1,
    rank: 67,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lycaon',
    char_three: 'Seth',
    app_rate: 0.1,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.1,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.1,
    rank: 67,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.07,
    rank: 82,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Ben',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.07,
    rank: 82,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 82,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.07,
    rank: 82,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.07,
    rank: 82,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 113,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 113,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 113,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 113,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 113,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Ben',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 113,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'Piper',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 113,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'nicole-demara',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 113,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Anton',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 113,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 113,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 113,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 113,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'jane-doe',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Corin',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 113,
    avg_round: 1,
    star_num: '4'
  }
];
